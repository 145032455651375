import { setDoc, doc } from "firebase/firestore/lite"
import React, { useState, useCallback } from "react"
import { fs, storage } from "../../../utils/firebase"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

export default function EditLocatin({ location }) {
  const [modifyLocation, setModifyLocation] = useState({ ...location })
  const handleChange = useCallback(
    e => setModifyLocation({ [e.target.name]: e.target.value }),
    []
  )
  const handleMerge = useCallback(
    () =>
      setDoc(doc(fs, "locations", modifyLocation.location), modifyLocation, {
        merge: true,
      })[modifyLocation]
  )

  return (
    <div>
      <div>
        <label htmlFor="subtitle">subtitle</label>
        <input
          name="subitle"
          value={modifyLocation.subtitle}
          onChange={handleChange}
        />
      </div>
      <div className="imgs-column">
        <p>banner</p>
        {modifyLocation.banner && (
          <img className="edit-location-banner" src={modifyLocation.banner} />
        )}
        <label htmlFor="location-banner">change banner</label>
        <input
          onChange={e => {
            uploadBytes(
              ref(storage, `${modifyLocation.location}/banner`),
              e.target.files[0]
            ).then(s =>
              getDownloadURL(s.ref).then(banner =>
                setModifyLocation(prev => ({ ...prev, banner }))
              )
            )
            // .catch(err => console.log({ err }))
          }}
          name="location-banner"
          type={"file"}
          accept="image/*"
        />
      </div>
      <div className="imgs-column">
        <p>img</p>
        {modifyLocation.img && (
          <img className="edit-location-img" src={modifyLocation.img} />
        )}
        <label htmlFor="loc-img">change img</label>
        <input
          onChange={e => {
            uploadBytes(
              ref(storage, `${modifyLocation.location}/img`),
              e.target.files[0]
            ).then(s =>
              getDownloadURL(s.ref).then(img =>
                setModifyLocation(prev => ({ ...prev, img }))
              )
            )
            // .catch(err => console.log({ err }))
          }}
          name="loc-img"
          type={"file"}
          accept="image/*"
        />
      </div>
      <button onClick={handleMerge}>save</button>
    </div>
  )
}
